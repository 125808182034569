import React from 'react';
import styles from './OpenPosition.module.scss';
import { OpenPositionCard } from "./OpenPositionCard";

export const OpenPositions = ({ openPositions }) => {
  return <div className={ styles.container }>
    <h3>Open Position{ openPositions.length > 1 && 's' }</h3>
    <div className={ styles.positionContainer }>{
      openPositions.map(({ data }) => (
        <OpenPositionCard title={ data?.Title }
                          description={ data?.Work_Environment }
        />
      ))
    }</div>
  </div>
};