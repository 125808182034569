/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import "./TestimonialCard.scss";
import { FluidImage } from "../../../FluidImage";
import { ThemeContext } from "../../../../components/Context/ThemeContext";

export const TestimonialCard = ({ name, title, quote, pictureSrc = "" }) => {
  const theme = useContext(ThemeContext);

  return <div
    className="careers-testimonial-card"
    css={ css`
      background-color: ${ (theme.lightAccent1) };
    ` }
  >
    {
      pictureSrc &&
      <div css={
        css` 
          position: relative; 
          width: 100px; 
          height: 100px; 
        `
      }>
        <FluidImage
          src={ pictureSrc }
          alt={ "image" }
          className="image"
        />
      </div>
    }
    <p className="name">
      { name }
    </p>
    <p className="careers-title">
      { title }
    </p>
    <div style={ { maxWidth: "100%" } }>
      <p className="quote">
        { quote }
      </p>
    </div>
  </div>;
};