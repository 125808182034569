/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

export const RightArrow = ({ className, onClick, color, fontSize }) => (
  <FontAwesomeIcon
    icon={ faChevronRight }
    className={ `${ styles.arrows } ${ styles.right } ${ className }` }
    css={ css`
      right: -30px;
      color: ${ color };
      font-size: ${ fontSize };
      &:hover {
        color: ${ color };
      }
      
      @media (min-width: 1024px) {
        right: -40px;
      }
    ` }
    onClick={ onClick }
  />
);