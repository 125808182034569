/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import SEO from "../seo";
import style from "./overview.module.scss";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { Hero } from "../../Core/Hero";
import { Container } from "../../Core/Container";
import { Breadcrumb } from "../../Core/Breadcrumb";
import { GalleryBlock } from "../../Core/Blocks/Gallery/GalleryBlock";
import { BlockFactory } from "../../Core/BlockFactory";
import { AllPagesContext } from "../Context/AllPagesContext";

export const Overview = ({ data }) => {
  useResetBlockRegistry();
  const { title } = data;
  let { siteStructure } = useContext(AllPagesContext);
  let solutions = [];
  let countOfSolutions;

  if (siteStructure) {
    
    let overviewStructure = siteStructure.solution[data.id];

    data?.regions?.Solutions?.$values.forEach((solution) => {
      let newSolution = overviewStructure.find((newSolution => newSolution.id === solution.id));
      if (newSolution) solutions.push(newSolution);
    });

    solutions.filter((solution) => solution?.page?.blocks.length > 0 && solution, []);
    countOfSolutions = solutions?.length;
  }

  return <>
    <SEO title={ title }/>
    <Hero
      title={ title }
      imageSrc={ data?.regions?.Main?.HeaderImage?.media?.publicUrl }
      description={ data?.regions?.Main?.Description?.value }
      maxHeight="40vh"
      alignItems="flex-start"
      justifyContent="flex-start"
    />
    <Container>
      <Breadcrumb crumbs={ [
        { path: "/", name: "Home" },
        { path: `/${ data.permalink }`, name: title }
      ] }/>
    </Container>
    <Container className={ `${ style.overview }` }>
      {
        data?.blocks?.length > 0 &&
        data?.blocks.map((block, index) => <BlockFactory block={ block } key={ index }/>)
      }
    </Container>

    <Container>
      <div className={ `${ style.allSolutions } title is-ancestor columns is-multiline is-centered` }>
        {
          solutions &&
          solutions.map((solution, index) => {
            const getRandomInt = (min, max) => {
              min = Math.ceil(min);
              max = Math.floor(max);

              return Math.floor(Math.random() * (max - min)) + min;
            };

            if ((index + 1) % 3 === 1 || (index + 1) === countOfSolutions) {
              return <div className={ `column ${ style.column }` }
                          css={ css` 
                            min-width: 300px; `
                          }
                          key={ index }
              >
                <GalleryBlock
                  title={ solution.title }
                  content={ solution?.regions?.Main?.SummaryText?.value || "" }
                  imageUrl={ solution?.regions?.Main?.BackgroundImage?.media?.publicUrl }
                  internalLink={ solution.permalink }
                />
              </div>;
            }

            let number = getRandomInt(3, 6);
            return <div className={ `column is-${ number } ${ style.column }` }
                        css={ css` 
                          min-width: 300px; `
                        }
                        key={ index }
            >
              <GalleryBlock
                title={ solution.title }
                content={ solution?.regions?.Main?.SummaryText?.value || "" }
                imageUrl={ solution?.regions?.Main?.BackgroundImage?.media?.publicUrl }
                internalLink={ solution.permalink }
              />
            </div>;
          })
        }
      </div>
    </Container>
  </>;
};