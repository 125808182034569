/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";

export const Facebook = ({ color, fontSize, link }) => (
  <a
    href={ link }
    target="_blank"
    rel="noreferrer"
  >
    <FontAwesomeIcon
      icon={ faFacebookF }
      className={ styles.icon }
      css={ css`
              color: ${ color };
              font-size: ${ fontSize };
            ` }
    />
  </a>
);