import React from "react";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import "semantic-ui-css/components/dimmer.min.css"
import "semantic-ui-css/components/loader.css"

export const LoadingPage = () => (
  <Dimmer inverted active>
    <Loader size='big' inverted style={{ position: 'absolute', top: '50%', left: '50%' }}/>
  </Dimmer>
);

export const LoadingSegment = () => <div style={ { padding: "80px 10px" } }><Loader active inline={ "centered" }/></div>;