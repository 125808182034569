/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from 'react'
import { ThemeContext } from '../../Context/ThemeContext'
import styles from './CompanyOverview.module.scss'

export const CompanyOverview = ({ overview }) => {
  let [theme] = useContext(ThemeContext)

  return (
    <div css={ css`
          color: ${ theme.lightFont };
          background-color: ${ theme.primaryColor };
         ` }
         className={styles.container}
    >
      <p className={styles.text}>
        { overview }
      </p>
    </div>
  )
}