/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { ThemeContext } from "../../../components/Context/ThemeContext";
import Slider from "react-slick";
import { TestimonialCard } from "./TestimonialCard";
import { BlockContainer } from "../BlockContainer";
import { RightArrow } from "../../Icons/RightArrow";
import { LeftArrow } from "../../Icons/LeftArrow";

const TestimonialSlider = ({ block }) => {
  let [theme] = useContext(ThemeContext);

  let settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: 0,
    infinite: true,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: <RightArrow
      color={ theme.darkAccent1 }
      fontSize={ "40px" }
    />,
    prevArrow: <LeftArrow
      color={ theme.darkAccent1 }
      fontSize={ "40px" }
    />,
    responsive: [
      {
        breakpoint: 1023, // desktop
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return <BlockContainer css={ css`
    padding: 20px 0 50px;
    
    @media (min-width: 1024px) {
      padding: 25px 0 0;
    }
  ` }>
    <Slider { ...settings }
            css={ css`
              @media (min-width: 1024px) {
                .slick-center .careers-testimonial-card {
                  background-color: ${ theme.lightBackground };
                  box-shadow: 0 1px 10px ${ theme.borderShadow }, 0 0 5px ${ theme.borderShadow };
                }
              }
            ` }
    >
      { block?.items.length > 0 &&
      block?.items.map((subBlock, key) => {
        return <TestimonialCard name={ subBlock?.author?.value }
                                title={ subBlock?.title?.value }
                                quote={ subBlock?.content?.value }
                                picture={ subBlock?.media?.publicUrl }
                                pictureSrc={ subBlock?.url }
                                key={ key }
        />;
      }) }
    </Slider>
  </BlockContainer>;
};

TestimonialSlider.propTypes = {};

export { TestimonialSlider };