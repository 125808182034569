import React from "react";
import { FluidImage } from "../../FluidImage";

export const ImageSizeBlock = ({ block, alt, style }) => {
  const tryToParseWidth = (value) => {
    const parsedInt = parseInt(value, 10);
    if (parsedInt) {
      return parsedInt;
    }
    return value;
  };

  return <div style={ {
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: tryToParseWidth(block.selectedWidthOption.width),
    padding: "20px 10px",
    margin: "0 auto"
  } }>
    <FluidImage src={ block.image.media.publicUrl } style={ { ...style } }/>
  </div>;
};