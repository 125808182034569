/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO ({ author, description, lang, meta, title, schema }) {

  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={ {
        lang
      } }
      title={ title }
      titleTemplate={ `%s | TSI Data` }
      meta={ [
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: "TSIDATA"
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta) }
    >
      <script type={ "application/ld+json" }>{ JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://tsidata.com",
        "name": "TSI Data",
        "contactPoint": {
          "@type": "ContactPoint",
          "openingHours": "Mo,Tu,We,Th,Fr 07:00-06:00",
          "telephone": "(817)-785-0095",
          "contactType": "Customer service",
          "email": "mailto:contact@tsienergy.com",
          "address": "1000 Post & Paddock St Grand Prairie Texas 75050"
        }
      }) }</script>
      <script type={ "application/ld+json" }>{ JSON.stringify(schema) }</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  schema: {}
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  schema: PropTypes.object
};

export default SEO;
