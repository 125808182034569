import React, { useEffect, useState } from "react";
import { Layout } from "./Layout/Layout";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "./Context/ThemeContext";
import { Helmet } from "react-helmet";
import { LoadingPage } from "../Core/Loading";
import { Home } from "./Home";
import { AllPagesProvider } from "./Context/AllPagesContext";
import { BrandProvider } from "./Context/BrandContext";
import { About } from "./About";
import { Overview } from "./Overview";
import { Solution } from "./Solution";
import { Careers } from "./Careers";
import { Contact } from "./Contact";
import { GetAllPages, GetMenuLinks, GetTheme } from "./GetData";
import ReactGA from "react-ga";
import { FooterPolicy } from "./FooterPolicy";
import ScrollToTop from "./ScrollToTp";

ReactGA.initialize('UA-154510071-1');
ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  let [appData, setAppData] = useState(null);

  useEffect(() => {
    if (!appData) {
      Promise.all([GetAllPages(), GetMenuLinks(), GetTheme()])
        .then((response) => {
          setAppData({
            pages: response[0],
            header: response[1].header,
            footer: response[1].footer,
            theme: response[2]
          });
        });
    }
  }, [appData]);

  if (!appData) return <LoadingPage/>;

  return (
    <BrowserRouter>
      <ThemeProvider theme={ appData.theme }>
        <BrandProvider>
          <AllPagesProvider pages={ appData.pages }>
            <Helmet>
              <link rel="icon" href="../images/TSI-Signature-Sized.png"/>
            </Helmet>
            <Layout footer={ appData.footer } header={ appData.header } social={ appData.social }>
              <ScrollToTop />
              <Switch>
                {
                  appData?.pages?.length > 0 &&
                  appData.pages.map((page, index) => {
                    if (page.permalink === "/")
                      return <Route exact path={ `/` } render={ () => render(page.typeId, page) }
                                    key={ index }/>;

                    return <Route path={ `${ page.permalink }` } render={ () => render(page.typeId, page) }
                                  key={ index }
                    />;
                  })
                }
                <Route path={ "/Contact" } component={ Contact }/>
              </Switch>
            </Layout>
          </AllPagesProvider>
        </BrandProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const defaultPages = {
  homepage: (data) => <Home data={ data }/>,
  about: (data) => <About data={ data }/>,
  overview: (data) => <Overview data={ data }/>,
  solution: (data) => <Solution data={ data }/>,
  careers: (data) => <Careers data={ data }/>,
  footerpolicy: (data) => <FooterPolicy data={ data }/>
};

export function render (pageType, data) {
  let correctPageType = pageType ? pageType.toLowerCase() : null;

  let render = defaultPages[correctPageType];
  if (render) {
    return render(data);
  }
  return <></>;
}

export default App;
