import React from "react";
import { BlockContainer } from "../BlockContainer";
import styles from "./Audio.module.scss";

export const Audio = ({ block }) => {
  const url = block?.body?.media?.publicUrl;

  return url && <BlockContainer flex>
    {
      url ?
        <audio className={ styles.audio } controls>
          <source src={ url } className={ styles.source }/>
          Your browser does not support the audio clip. Please switch to a modern browser.
        </audio> :
        <p>Currently unable to play audio clip.</p>
    }
  </BlockContainer>;
};