/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";

export const Whatsapp = ({ color, fontSize, link }) => {

  return (
    <a
      href={ link }
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={ faWhatsapp }
        className={ styles.icon }
        css={ css`
              color: ${ color };
              font-size: ${ fontSize };
            ` }
      />
    </a>
  );
};