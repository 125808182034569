import React from "react";
import SEO from "../seo";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { Hero } from "../../Core/Hero";
import { Container } from "../../Core/Container";
import { Breadcrumb } from "../../Core/Breadcrumb";
import { BlockFactory } from "../../Core/BlockFactory";

export const FooterPolicy = ({ data }) => {
  useResetBlockRegistry();

  const { title } = data;

  return <>
    <SEO title={ title || "Policy" }/>
    <Hero title={ title }
          description={ data?.regions?.Main?.Description?.value }
          imageSrc={ data.regions?.Main?.BackgroundImage?.media?.publicUrl }
          justifyContent={ "flex-end" }
          alignItems={ "flex-start" }
          maxHeight={ "20vh" }
    />
    <Container>
      <Breadcrumb crumbs={ [
        { name: "Home", path: "/" },
        { name: title, path: `/${ data.permalink }` }
      ] }/>
    </Container>
    <Container>
      {
        data?.blocks?.length > 0 &&
        data?.blocks.map(block => <BlockFactory block={ block } key={ block.id }/>)
      }
    </Container>
  </>;
};