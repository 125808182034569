/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './LabeledTextArea.module.scss';
import { FloatingLabel } from '../FloatingLabel';
import { ThemeContext } from "../../../components/Context/ThemeContext";

export const LabeledTextArea = ({ label, children, className, id, ...props }) => {
  let [theme] = useContext(ThemeContext);

  return <>
    { !!label && (
      <FloatingLabel
        className={ `${ className }-label` }
        htmlFor={ id }
      >
        { label }
      </FloatingLabel>
    ) }
    <textarea
      name="notes"
      className={ `${ styles.labeledTextarea } ${ className }` }
      css={ css`
        border: 2px solid ${ theme.darkFont };
      ` }
      { ...props }
    />
    { children }
  </>
};

LabeledTextArea.propTypes = {
  label: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  id: PropTypes.string
};

LabeledTextArea.defaultProps = {
  label: '',
  children: null,
  className: null,
  id: null
};
