/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./Gallery.module.scss";
import { GalleryBlock } from "./GalleryBlock";
import { ThemeContext } from "../../../components/Context/ThemeContext";
import { BlockContainer } from "../BlockContainer";

export const Gallery = ({ block }) => {
  let [theme] = useContext(ThemeContext);

  return <BlockContainer>
    { block?.title && <h2 className={ styles.title }>{ block.title }</h2> }
    { block?.content && <p className={ styles.content }>{ block.content }</p> }
    <div
      className={ styles.postContainer }
      css={ css`
        color: ${ theme.lightFont };
      ` }
    >
      { block?.items?.length > 0 &&
      block.items.map((block, index) => {
        return <GalleryBlock title={ block?.title?.value }
                             content={ block?.content?.value }
                             imageUrl={ block?.image?.media?.publicUrl }
                             link={ block?.pageLink?.value }
                             key={ index }
        />;
      }) }
    </div>
  </BlockContainer>;
};