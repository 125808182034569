import React, { useContext, useEffect, useState } from "react";
import SEO from "../seo";
import { ContactUsBanner } from "../Contact";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { DarkenOnHoverLink } from "../../Core/DarkenOnHoverLink";
import { AllPagesContext } from "../Context/AllPagesContext";
import { LoadingSegment } from "../../Core/Loading";
import { Hero } from "../../Core/Hero";
import { Container } from "../../Core/Container";
import { Breadcrumb } from "../../Core/Breadcrumb";
import { MasterDetail } from "../../Core/MasterDetail";
import { BlockFactory } from "../../Core/BlockFactory";

export const About = ({ data }) => {
  useResetBlockRegistry();
  const [pageData, setPageData] = useState(data);
  let { siteStructure } = useContext(AllPagesContext);

  const title = pageData?.title;
  const pageSlug = pageData?.permalink;

  useEffect(() => {
    setPageData(data);
  }, [data]);

  if (!data) return <LoadingSegment/>;

  return <>
    <SEO title={ title || "About" }/>
    <Hero title={ title }
          description={ pageData?.regions?.Main?.Description?.value }
          justifyContent={ "flex-end" }
          alignItems={ "flex-start" }
          maxHeight={ "40vh" }
          imageSrc={ pageData?.regions?.Main?.BackgroundImage?.media?.publicUrl }
    />
    <Container>
      <Breadcrumb crumbs={ [
        { name: "Home", path: "/" },
        { name: title, path: `/${ pageSlug }` }
      ] }/>
    </Container>
    <Container>
      <MasterDetail
        Master={
          siteStructure?.about ? <div style={ { display: "flex", flexFlow: "column nowrap" } }>
            <h3>About Us</h3>
            {
              siteStructure.about?.length > 0 &&
              siteStructure.about.map((page, index) => (
                page.title &&
                <DarkenOnHoverLink to={ `${ page.permalink }` }
                                   key={ index }
                                   currentPage={ title === page.title }
                >
                  { page.title }
                </DarkenOnHoverLink>
              ))
            }
          </div> : <LoadingSegment/>
        }>
        <>{
          pageData?.blocks &&
          pageData?.blocks.map(block => <BlockFactory block={ block } key={ block.id }/>)
        }</>
      </MasterDetail>
    </Container>
    <ContactUsBanner/>
  </>;
};