/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";

export const LeftArrow = ({ className, onClick, color, fontSize }) => (
  <FontAwesomeIcon
    icon={ faChevronLeft }
    className={ `${ styles.arrows } ${ styles.left } ${ className }` }
    css={ css`
      left: -30px;
      color: ${ color };
      font-size: ${ fontSize };
      &:hover {
        color: ${ color };
      }
      
      @media (min-width: 1024px) {
        left: -40px;
      }
    ` }
    onClick={ onClick }
  />
);