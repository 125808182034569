import React from "react";
import { BlockContainer } from "../BlockContainer";
import { ImageSizeBlock } from "../ImageSizeBlock";

const findClassName = (imageSize) => {
  if (imageSize === "auto") return;

  const parsedInt = parseInt(imageSize, 10);
  if (parsedInt <= 300) {
    return "is-3";
  }
  if (parsedInt <= 500) {
    return "is-5";
  }
  if (parsedInt <= 700) {
    return "is-7";
  }
  if (parsedInt <= 900) {
    return "is-9";
  }

  return "is-12";
};

export const ImageGallery = ({ block }) => (
  <BlockContainer>
    <div className={ `columns is-multiline is-vcentered is-centered` }>
      { block?.items?.length > 0 &&
      block.items.map((image, index) => {
        return <div className={ `column ${ findClassName(image.selectedWidthOption.width) }` } key={ index }>
          <ImageSizeBlock block={ image }/>
        </div>;
      }) }
    </div>
  </BlockContainer>
);