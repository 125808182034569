/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./GalleryCircle.module.scss";
import { FluidImage } from "../../../FluidImage";
import { ThemeContext } from "../../../../components/Context/ThemeContext";

export const GalleryCircle = ({ title = "", picture, overlay = false, width = "150px", height = "150px" }) => {
  const theme = useContext(ThemeContext);

  return <div
    className={ ` ${ styles.circleContainer }` }
    css={ css`
      width: ${ width };
      height: ${ height };
      
      ${ overlay && `
        &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: ${ theme.imageOverlayColorSolution };
        opacity: .25;
        border-radius: 50%;
      }
      ` }
    ` }
  >
    {
      picture &&
      <FluidImage src={ picture }
                  alt={ "gallery circle image" }
                  className={ styles.image }
      />
    }
    <div className={ styles.content }>
      <h3 className={ styles.title }
          css={ css`
          color: ${ theme.lightFont }; 
        ` }
      >
        { title }
      </h3>
    </div>
  </div>;
};