import React from "react";
import ReactHtmlParser from "react-html-parser";
import style from "./HtmlParset.module.scss";
import { BlockContainer } from "../BlockContainer";

export const HtmlParser = ({ block, value }) => {
  const html = block?.body?.value;

  if (html)
    return <Parser value={ html }/>;

  if (value)
    return <Parser value={ value }/>;

  return <></>;
};

const Parser = ({ value }) => (
  <BlockContainer>
    <div className={ `${ style.container }` }>
      { ReactHtmlParser(value) }
    </div>
  </BlockContainer>
);
