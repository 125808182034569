/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

export const LinkFactory = ({ to, children, ...props }) => {
  if (to && (to.includes("https") || to.includes("http"))) {
    return <a href={ to } target="_blank" rel="noreferrer" { ...props }>{ children }</a>;
  }
  if (to) {
    return <Link to={ to } { ...props }>{ children }</Link>;
  }

  return <a css={ css`cursor: pointer;` } { ...props }>{ children }</a>;
};