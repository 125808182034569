/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./ContactUs.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";
import { SolidContactButton } from "../Buttons";

export const ContactUsBanner = () => {
  let [theme] = useContext(ThemeContext);

  return <div
    className={styles.contactUsContainer}
  >
    <div
      className={styles.container}
      css={css`
        border-top: 3px solid ${theme.primaryColor};
        border-bottom: 3px solid ${theme.primaryColor};
        padding: 65px 0;
        width: fit-content;
        margin: auto;
      `}
    >
      <h3
        css={css`
          color: ${theme.primaryColor};
        `}
      >
        Learn about how TSI can help with your business needs
      </h3>
      <SolidContactButton hover/>
    </div>
  </div>;
};