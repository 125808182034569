import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export const ThemeContext = React.createContext([{}, () => {
}]);

const breakpoints = {
  mobileBP: "768px",
  tabletBP: "769px",
  desktopBP: "1024px",
  wideScreenBP: "1216px",
  fullHdBP: "1408px"
};

export const ThemeProvider = ({ children, theme = breakpoints, ...props }) => {
  const [state, setState] = useState({ ...breakpoints, ...theme });

  useEffect(() => {
    setState({ ...breakpoints, ...theme });
  }, [theme]);

  return <ThemeContext.Provider value={ [state, setState] } { ...props }>
    { children }
  </ThemeContext.Provider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object
};