import React from "react";
import styles from "./Video.module.scss";
import { BlockContainer } from "../BlockContainer";

export const Video = ({ block }) => {
  const url = block?.body?.media?.publicUrl;

  return url && <BlockContainer flex alignment='center'>
    { url ?
      <video
        controls
        className={ styles.video }
      >
        <source
          src={ url }
        />
        Your browser doesn't support this video. Please switch to a modern browser.
      </video> :
      <p>Currently unable to play video clip.</p>
    }
  </BlockContainer>;
};