import ConvertToCamelCase from "../../Core/util/ConvertToCamelCase";

export const formatThemeArray = (array) => {
  return array.reduce((acc, curr) => {
    let name = ConvertToCamelCase(curr.name);

    return {
      ...acc,
      [name]: curr.color
    };
  }, {});
};