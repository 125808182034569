import React from "react";
import SEO from "../seo";
import styles from "./Contact.module.scss";
import { Info } from "./Info";
import { Form } from "./Form";

export const Contact = () => {
  return <>
    <SEO title="Contact"/>
    <div className={ styles.contact }>
      <Info/>
      <Form/>
    </div>
  </>;
};