/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import { LinkFactory } from "../../../Core/LinkFactory";

export const SolidContactButton = ({ hover = false, hoverColor = null, padding = "6px 14px", ...props }) => {
  let [theme] = useContext(ThemeContext);

  return <LinkFactory to="/Contact">
    <button
      css={ css`
      cursor: pointer;
      background-color: ${ theme.primaryColor };
      color: ${ theme.lightFont };
      padding: ${ padding };
      border: 2px solid transparent;
      border-radius: 5px;
      transition: all 0.1s ease-in-out;
      margin-left: 80px;
        
      ${
        hover &&
        `:hover {
          background-color: transparent;
          border: 2px solid ${ theme.primaryColor };
          color: ${ hoverColor || theme.primaryColor };
          `
      }
      ` }
      { ...props }
    >
      Contact Us
    </button>
  </LinkFactory>;
};