import React, { useContext } from "react";
import styles from "./Quote.module.scss";
import { ThemeContext } from "../../../components/Context/ThemeContext";
import { BlockContainer } from "../BlockContainer";
import { LeftQuote } from "../../Icons/LeftQuote";

export const Quote = ({ block }) => {
  let [theme] = useContext(ThemeContext);
  let quote = block?.body?.value;

  if (quote) {
    return <BlockContainer flex>
      <LeftQuote fontSize="20px" color={ theme.primaryColor }/>
      <h3 className={ styles.quote }>
        { quote }
      </h3>
    </BlockContainer>;
  }

  return <></>;
};