import React from "react";
import { Facebook } from "../Icons/Facebook";
import { GooglePlus } from "../Icons/GooglePlus";
import { Instagram } from "../Icons/Instagram";
import { Linkedin } from "../Icons/Linkedin";
import { Medium } from "../Icons/Medium";
import { Twitter } from "../Icons/Twitter";
import { Reddit } from "../Icons/Reddit";
import { Whatsapp } from "../Icons/Whatsapp";
import { Youtube } from "../Icons/Youtube";

const defaultRenderers = {
  facebook: (block) => <Facebook { ...block }/>,
  googleplus: (block) => <GooglePlus { ...block } />,
  instagram: (block) => <Instagram { ...block } />,
  linkedin: (block) => <Linkedin { ...block } />,
  medium: (block) => <Medium { ...block } />,
  twitter: (block) => <Twitter { ...block } />,
  reddit: (block) => <Reddit { ...block } />,
  whatsapp: (block) => <Whatsapp { ...block } />,
  youtube: (block) => <Youtube { ...block } />
};

let renderers = Object.assign({}, defaultRenderers);

export function renderSocialMedia (blockType, blockData) {
  let block = blockType && blockType.toLowerCase();

  let renderer = renderers[block];
  if (renderer) {
    return renderer(blockData);
  }
  console.error(`There was no icon for ${ blockType }`);
  return <></>;
}