/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { BlockContainer } from "../BlockContainer";
import { FluidImage } from "../../FluidImage";
import { ThemeContext } from "../../../components/Context/ThemeContext";

export const ImageBlock = ({ url }) => {
  const theme = useContext(ThemeContext);

  return <BlockContainer css={ css`
    position: relative;
    width: 100%;
    height: 100%;
  ` }>
    {
      url && <FluidImage
        src={ url }
        alt={ "Image block" }
        css={ css`
          border-radius: 5px;
          box-shadow: 2px 2px 5px ${ theme.borderShadow };
      ` }
      />
    }
  </BlockContainer>;
};