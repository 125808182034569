/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlusG } from "@fortawesome/free-brands-svg-icons/faGooglePlusG";

export const GooglePlus = ({ color, fontSize, link }) => (
  <a
    href={ link }
    target="_blank"
    rel="noreferrer"
  >
    <FontAwesomeIcon
      icon={ faGooglePlusG }
      className={ styles.icon }
      css={ css`
              color: ${ color };
              font-size: ${ fontSize };
            ` }
    />
  </a>
);