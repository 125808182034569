/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './FloatingLabel.module.scss';
import { ThemeContext } from "../../../components/Context/ThemeContext";

export const FloatingLabel = ({ children, className, ...props }) => {
  let [theme] = useContext(ThemeContext);

  return <div className={ `${ styles.floatingLabelContainer } ${ className }-container` }>
    <label
      className={ `${ styles.floatingLabel } ${ className }` }
      css={ css`
        background-color: ${theme.lightBackground};
        color: ${theme.darkFont};
      ` }
      { ...props }
    >
      { children }
    </label>
  </div>
};

FloatingLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

FloatingLabel.defaultProps = {
  className: null,
  children: null
};