/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "./NavbarAnnim.css";
import { useContext, useState } from "react";
import styles from "./Header.module.scss";
import { ThemeContext } from "../Context/ThemeContext";
import { lighten } from "polished";
import { Link } from "react-router-dom";
import { SolidContactButton } from "../Contact/Buttons";
import { Container } from "../../Core/Container";
import { TSILogo } from "../../Core/Icons/TSILogo";


const Header = ({ header }) => {
  let [theme] = useContext(ThemeContext);

  const [activeMenu, setActiveMenu] = useState(false);

  const onMenuClick = () => {
    setActiveMenu(!activeMenu);
  };

  return (
    <header
      className={ styles.navigation }
      css={ css`
        background-color: ${ theme.darkBackground };
      ` }
    >
      <Container
        className={ `${ styles.navigationBar } navbar` }
        role="navigation"
        aria-label="main navigation"
        css={ css`
          background-color: ${ theme.darkBackground };
        ` }
      >
        <div className={ styles.mobileFlex }>
          <div className={ styles.logoSpace }>
            <Link to="/" style={ { lineHeight: "0", width: "45px" } }>
              <TSILogo/>
            </Link>
          </div>
          <div role="button" className={ `navbar-burger burger ${ activeMenu && "is-active" }` }
               onClick={ onMenuClick }
               aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <div className="menu-btn">
              <div className="menu-btn__burger" />
            </div>
          </div>
        </div>
        <div className={ `navbar-menu ${ styles.navbarEndContainer } ${ activeMenu && "is-active" }` }>
          <div
            className={ `navbar-end ${ styles.navbarEnd }` }
            css={ css`
              background-color: ${ theme.darkBackground };
              * {
                color: ${ theme.lightFont };
              }
              a {
                &:visited {
                  color: ${ theme.lightFont };
                }
                &:active {
                  color: ${ theme.lightFont };
                }
                &:link {
                  color: ${ theme.lightFont };
                }
                &:hover {
                  color: ${ theme.lightFont };
                  background-color: ${ theme.darkBackground && lighten(.15, theme.darkBackground) };
                  border-radius: 3px;
                }
              }
            ` }
          >
            {
              header &&
              header.length > 0 &&
              header.map((link, parentIndex) => {
                if (link.items.length > 0) {
                  return <div className={ `navbar-item has-dropdown is-hoverable` }
                            css={ css`
                            cursor: pointer;
                            span {
                              &:hover {
                                color: ${ theme.lightFont };
                                background-color: ${ theme.darkBackground && lighten(.15, theme.darkBackground) };
                                border-radius: 3px;
                              }
                            }` }
                            key={parentIndex}
                          >
                            <span className={ `${ styles.navigationLink } ${styles.topLevelLink}` }>
                              About
                            </span>
                            <div
                              className={ `navbar-dropdown ${ styles.navbarDropdown }` }
                              css={ css`
                              background-color: ${ theme.darkBackground };
                              border: none;
                              margin-left: ${activeMenu? "0" : "-25%"};
                              min-width: ${activeMenu? "0" : "150%"};
                              padding-bottom: 0px;
                              text-align: center;
                              ` }>
                                  {
                                    link.items.map((sublink, subkey) => {
                                        return activeMenu? <Link
                                              to={ `${ sublink.permalink }` }
                                              className={ `${ styles.navigationSubLink } dropdownItems` }
                                              key={ subkey }
                                              onClick={(event) => { event.target.blur(); onMenuClick()}}
                                            >
                                          { sublink.title || '' }
                                        </Link> : 
                                        <Link
                                          to={ `${ sublink.permalink }` }
                                          className={ `${ styles.navigationSubLink }` }
                                          key={ subkey }
                                          onClick={(event) => { event.target.blur(); }}
                                        >
                                          { sublink.title || '' }
                                        </Link>
                                    })
                                  }
                            </div>
                          </div>
                }

                return (activeMenu? <Link to={ `${ link.permalink }` }
                                            className={ `navbar-item ${ styles.navigationLink } ` }
                                            css={ css`
                                            margin: 0;
                                            `}
                                            key={ parentIndex }
                                            onClick={ onMenuClick }
                                    >
                                      { link.navigationTitle || link.title || "" }
                                    </Link> :
                                    <Link to={ `${ link.permalink }` }
                                    className={ `navbar-item ${ styles.navigationLink } ` }
                                    key={ parentIndex }
                                    >
                                      { link.navigationTitle || link.title || "" }
                                    </Link>);
              })
            }
            <div css={ css`
              padding-bottom: 7px;
              padding-top: 7px;
              padding-right: 7px;
              a {
                &:hover {
                  background-color: ${ theme.darkBackground};
                }
              }
            ` }>
              {activeMenu? 
                <SolidContactButton hover  hoverColor={ theme.lightFont } onClick={ onMenuClick } /> : 
                <SolidContactButton hover  hoverColor={ theme.lightFont }/>}
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;