/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import styles from "../Contact.module.scss";
import { GetContactInfo } from "../../GetData";
import { darken, lighten } from "polished";
import { LoadingSegment } from "../../../Core/Loading";

export const Info = () => {
  let [theme] = useContext(ThemeContext);
  let [data, setData] = useState(null);

  useEffect(() => {
    Promise.resolve(GetContactInfo())
      .then(response => {
        response.forEach(datum => datum.key = datum.name);
        setData(response);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  if (!data) return <LoadingSegment/>;

  return <div
    className={ styles.info }
    css={ css`
      &::after {
        background-color: ${ theme.darkFont };
      }
      a {
        color: ${ theme.darkFont };
      }
    ` }
  >
    {
      data.map((info, index) => {
        if (info.key.toLowerCase() === "location") {
          const splitAtFirstComma = new RegExp("^([^,]+),");
          const lines = info.value.split(splitAtFirstComma);

          return <div className={ styles.container } key={index}>
            <h3>{ info.key }</h3>
            { lines.map((line, index2) => (
              <p key={index2}>{ line }</p>
            )) }
          </div>;
        }

        return <div className={ styles.container } key={index}>
          <h3>{ info.key }</h3>
          {
            info.key.toLowerCase() === "phone number" ?
              <a
                href={ `tel:${ info.value.replace(/-|\.|\(|\)/g, "") }` }
                css={ css`
                  @media (pointer: fine) {
                  color: ${ lighten(.25, theme.darkBackground) };
                    &:hover {
                      color: ${ darken(.5, theme.darkBackground) };
                    }
                  }
                ` }
              >
                { info.value }
              </a> :
              <p>{ info.value }</p>
          }
        </div>;
      })
    }
  </div>;
};