/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";

export const Person = ({ color, fontSize }) => (
  <FontAwesomeIcon
    icon={ faUser }s
    className={ styles.icon }
    css={ css`
      color: ${ color };
      font-size: ${ fontSize };
      margin: 20px auto;
    ` }
  />
);