import React from "react";
import PropTypes from "prop-types";
import styles from "./Container.module.scss";

const Container = ({ children, className = "", ...props }) => {
  return <div className={styles.container + " " + className} {...props}>
    {children}
  </div>;
};

Container.propTypes = {
  children: PropTypes.node
};

export { Container };