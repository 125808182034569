import React from 'react'
import { FluidImage } from "../FluidImage";
import logo from "../../images/TSI-Signature-Sized.png";

export const TSILogo = () => {
  return (
    <FluidImage
      alt="TSI Logo"
      src={logo}
    />
  )
}