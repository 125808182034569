import React from "react";
import PropTypes from "prop-types";
import styles from "./BlockContainer.module.scss";

export const BlockContainer = ({ title, children, alignment, titleAlignment, flex, flexFlow, justifyContent, alignItems, id, containerPadding, ...props }) => (
  <div
    className={ styles.block }
    style={ { padding: containerPadding } }
    { ...props }
  >
    <div id={ id } className={ styles.blockId }/>
    { title && <h2 style={ { textAlign: titleAlignment, lineHeight: "38px", margin: 0 } }>{ title }</h2> }
    <div style={ {
      textAlign: alignment,
      lineHeight: "32px",
      display: `${ flex ? "flex" : "block" }`,
      flexFlow: flexFlow,
      justifyContent: justifyContent,
      alignItems: alignItems
    } }>{ children }</div>
  </div>
);

BlockContainer.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  alignment: PropTypes.oneOf(["left", "right", "center"]),
  titleAlignment: PropTypes.oneOf(["left", "right", "center"]),
  flex: PropTypes.bool,
  flexFlow: PropTypes.oneOf(["row nowrap", "row wrap", "column nowrap", "column wrap"]),
  justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
  alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center", "baseline", "stretch"]),
  containerPadding: PropTypes.string
};

BlockContainer.defaultProps = {
  alignment: "left",
  titleAlignment: "center",
  flex: false,
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  containerPadding: "20px"
};