/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./OpenPositionCard.module.scss";
import { lighten } from "polished";
import { ThemeContext } from "../../../Context/ThemeContext";
import { Person } from "../../../../Core/Icons/Person";

export const OpenPositionCard = ({ title, description }) => {
  let [theme] = useContext(ThemeContext);

  return <div
    className={ styles.container }
    css={ css`
      background-color: ${ lighten(.1, theme.primaryColor) };
      
      * {
        color: ${ theme.lightFont };
      }
    ` }
  >
    <p className={ styles.title }>{ title }</p>
    <Person
      color={ theme.darkAccent1 }
      fontSize={ "65px" }
    />
    <p className={ styles.description }>{ description }</p>
  </div>;
};