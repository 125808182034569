/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import styles from "./footer.module.scss";
import styled from "@emotion/styled";
import { darken } from "polished";
import { ThemeContext } from "../Context/ThemeContext";
import { renderSocialMedia } from "../../Core/SocialMediaFactory";
import { BrandContext } from "../Context/BrandContext";
import { GetFooterPolicies } from "../GetData";
import { Container } from "../../Core/Container";
import { LinkFactory } from "../../Core/LinkFactory";

export const Footer = ({ footer }) => {
  let [theme] = useContext(ThemeContext);
  let { socialMedia } = useContext(BrandContext);
  let [footerPolicyLinks, setFooterPolicyLinks] = useState([]);

  const LinksContainer = styled.div`
    padding: 0;
    
    @media screen and (min-width: ${ theme.tabletBP }) {
      padding: 0 15px;
    }
  `;

  const LinksDiv = styled.div`
    display: flex;
    flex-flow: column nowrap;
  `;

  useEffect(() => {
    Promise.resolve(GetFooterPolicies())
    .then(response => {
      setFooterPolicyLinks(response);
    })
  }, []);

  return (
    <footer
      css={ css`
        background-color: ${ theme.darkBackground };
      ` }
    >
      <Container className={ styles.footerBar }>
        <div
          className={ styles.footerLinks }
          css={ css`
            a {
              color: ${ theme.lightFont };
            }
          ` }
        >
          {
            footer &&
            footer.length > 0 &&
            footer.map((section, index) => {
              return <LinksContainer key={ index }>
                { section.title &&
                <LinkFactory to={ section.permalink }
                             css={ css`color: ${ theme.lightFont };
                                font-size: 16px;
                                font-weight: bold;
                                margin-bottom: 1.45rem;
                                
                                @media screen and (min-width: ${ theme.desktopBP }) {
                                 font-size: 20px; 
                                }` }
                >
                  { section.title }
                </LinkFactory>
                }
                { section.items.length > 0 &&
                <LinksDiv>
                  { section.items.map((link, index) => {
                    return <LinkFactory
                      to={ link.permalink }
                      key={ index }
                      css={ css`
                        font-size: 12px;
                        text-decoration: none;
                        padding: 10px 10px;
                    
                        @media screen and (min-width: ${ theme.tabletBP }) {
                          font-size: 16px;
                        }
                      ` }
                    >
                      { link.title }
                    </LinkFactory>;
                  }) }
                </LinksDiv>
                }
              </LinksContainer>;
            })
          }
        </div>
        <div className={ styles.social }>
          {
            socialMedia &&
            socialMedia.length > 0 &&
            socialMedia.map((media, index) => {
              media.color = theme.lightFont;
              return <div key={ index }>{ renderSocialMedia(media.name, media) }</div>;
            })
          }
        </div>
      </Container>
      <div
        className={ styles.copyRight }
        css={ css`
          background-color: ${ theme.darkBackground && darken(.2, theme.darkBackground) };
          color: ${ theme.mediumAccent1 };
        ` }
      >
        <p>&copy; Triencon Services, Inc.</p>
        {
          footerPolicyLinks.length > 0 &&
          footerPolicyLinks.map((link, index) => {
            return <LinkFactory
              to={ link.permalink }
              key={ index }
            >
              { link.title }
            </LinkFactory>;
          })
        }
      </div>
    </footer>
  );
};