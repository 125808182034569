export const GetMenuLinks = () => {
    return (
        {
            "header": [
              {
                "id": "53424d42-1fd1-4e21-9236-6ae1f59623a7",
                "title": "IT",
                "permalink": "/information-technology",
                "items": []
              },
              {
                "id": "892d03d2-7f64-4c2f-a1de-8ec7c5a07745",
                "title": "OT",
                "permalink": "/operation-technology",
                "items": []
              },
              {
                "id": "6a48ca55-f9a3-4e22-bc87-f4fda59ad814",
                "title": "DEV",
                "permalink": "/application-website-development",
                "items": []
              },
              {
                "id": "aabd72c7-8088-4ddb-bca9-ceca32dae84e",
                "title": "PI",
                "permalink": "/pi",
                "items": []
              },
              {
                "id": "fgh",
                "title": "Careers",
                "permalink": "/Careers",
                "items": []
              },
              {
                "id": "43cc7857-2dcd-4c36-afb1-20b3dfc292ac",
                "title": "About",
                "permalink": null,
                "items": [
                  {
                    "id": "c80a2bf7-5195-437d-a765-ca260b85c758",
                    "title": "Technical Partners",
                    "permalink": "/technical-partners"
                  },
                  {
                    "id": "e4dcda5d-914b-428c-a68a-851e5f47b53a",
                    "title": "Company Overview",
                    "permalink": "/company-overview"
                  },
                  {
                    "id": "1fbf0402-049b-4c44-8193-6dfe3e997b6c",
                    "title": "Culture and Core Values",
                    "permalink": "/culture-and-core-values"
                  }
                ]
              }
            ],
            "footer": [
              {
                "id": "24781bed-9fdd-40fa-aa23-18dd4e1e9751",
                "title": "Services",
                "permalink": null,
                "items": [
                  {
                    "id": "53424d42-1fd1-4e21-9236-6ae1f59623a7",
                    "title": "Information Technology",
                    "permalink": "/information-technology"
                  },
                  {
                    "id": "892d03d2-7f64-4c2f-a1de-8ec7c5a07745",
                    "title": "Operation Technology",
                    "permalink": "/operation-technology"
                  },
                  {
                    "id": "6a48ca55-f9a3-4e22-bc87-f4fda59ad814",
                    "title": "Application Development",
                    "permalink": "/application-website-development"
                  },
                  {
                    "id": "aabd72c7-8088-4ddb-bca9-ceca32dae84e",
                    "title": "OSISoft PI Certified Premier Partner",
                    "permalink": "/pi"
                  }
                ]
              },
              {
                "id": "0c5f9fd1-c735-4fe0-9bcd-5535febd2fa9",
                "title": "About",
                "permalink": null,
                "items": [
                  {
                    "id": "c80a2bf7-5195-437d-a765-ca260b85c758",
                    "title": "Technical Partners",
                    "permalink": "/technical-partners"
                  },
                  {
                    "id": "e4dcda5d-914b-428c-a68a-851e5f47b53a",
                    "title": "Company Overview",
                    "permalink": "/company-overview"
                  },
                  {
                    "id": "1fbf0402-049b-4c44-8193-6dfe3e997b6c",
                    "title": "Culture and Core Values",
                    "permalink": "/culture-and-core-values"
                  }
                ]
              },
              {
                "id": "ad74eeb1-9081-4412-84be-7b0e5772bb9e",
                "title": "Resources",
                "permalink": null,
                "items": [
                  {
                    "id": "7697d23b-0031-46c1-8d65-cc07ecbbfce7",
                    "title": "Careers",
                    "permalink": "/careers"
                  },
                  {
                    "id": "7b0d3686-fc3b-40e4-afac-6ff2f5bebbfb",
                    "title": "Contact Us",
                    "permalink": "/Contact"
                  }
                ]
              }
            ]
        }  
    )
}