export const GetBranding = () => {
    return (
        {
            "tagline": "Bridge the Gap with TSI",
            "social": [
              {
                "id": 1,
                "name": "Facebook",
                "link": "https://www.facebook.com/TSIData"
              },
              {
                "id": 2,
                "name": "Twitter",
                "link": "https://twitter.com/TSIData"
              },
              {
                "id": 3,
                "name": "LinkedIn",
                "link": "https://www.linkedin.com/company/triencon-services"
              }
            ]
        }          
    )
}