/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./Hero.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";
import { LinkFactory } from "../../../Core/LinkFactory";
import { GalleryCircle } from "../../../Core/Blocks/Gallery/GalleryCircle";
import { AllPagesContext } from "../../Context/AllPagesContext";
import { BrandContext } from "../../Context/BrandContext";
import { LoadingSegment } from "../../../Core/Loading";

export const Hero = ({ callToAction }) => {
  let [theme] = useContext(ThemeContext);
  const { tagline } = useContext(BrandContext);
  let { siteStructure } = useContext(AllPagesContext);
  let overviewWidth = "200px";

  if (!siteStructure?.overview) return <LoadingSegment/>;

  return (
    <div className={ `${ styles.hero }` }>
      <h1 className={ styles.tagline }>{ tagline }</h1>
      <p css={ css`
             color: ${ theme.darkFont };
            ` }
         className={ styles.cta }
      >
        { callToAction }
      </p>
      <div className={ styles.overviewContainer }>
        {
          siteStructure?.overview &&
          siteStructure?.overview.length > 0 &&
          siteStructure?.overview.map((overview, index) => {
            let homepageImage = overview?.regions?.Main?.HeaderImage?.media?.publicUrl;
            let overviewHeaderImage = overview?.regions?.Main?.HomepageImage?.media?.publicUrl;

            return <LinkFactory to={ `/${ overview?.slug }` }
                                className={ styles.overviewLink }
                                css={ css`
                            width: ${ overviewWidth };
                         ` }
                                key={ index }
            >
              <GalleryCircle
                picture={ overviewHeaderImage || homepageImage }
                width={ overviewWidth }
                height={ overviewWidth }
              />
              <h3 className={ styles.title }>{ overview?.title }</h3>
            </LinkFactory>;
          })
        }
      </div>
    </div>
  );
};