import React from 'react'
import styles from './Breadcrumb.module.scss'
import StringToPath from '../util/StringToPath'
import { DarkenOnHoverLink } from '../DarkenOnHoverLink'

export const Breadcrumb = ({ crumbs = [] }) => {

  return <nav className={ `breadcrumb ${ styles.breadcrumb }` } aria-label="breadcrumbs">
    <ul>
      {
        crumbs.map(crumb => {
          return <li key={ crumb.path }>
            <DarkenOnHoverLink to={ StringToPath(crumb.path) }>
              { crumb.name }
            </DarkenOnHoverLink>
          </li>
        })
      }
    </ul>
  </nav>
}