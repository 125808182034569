import React from "react";
import PropTypes from "prop-types";
import { render } from "./BlockRegistry";

const BlockFactory = ({ block }) => (
  <div style={{}}>
    {render(block?.type?.toLowerCase().replace(/\W+/, ""), block)}
  </div>
);

BlockFactory.propTypes = {
  addLink: PropTypes.func,
  addDownloadLink: PropTypes.func
};

export { BlockFactory };