import React from "react";
import {
  Audio,
  Columns,
  ContentSlider,
  Gallery,
  GalleryBlock,
  HtmlParser,
  ImageBlock,
  ImageGallery,
  Markdown,
  Quote, RelatedLinkGallery,
  Separator,
  TestimonialSlider,
  BlockContainer,
  Video, ImageSizeBlock
} from "../Blocks/";

const defaultRenderers = {
  htmlblock: (block) => <HtmlParser block={ block }/>,
  quoteblock: (block) => <Quote block={ block }/>,
  separatorblock: (block) => <Separator/>,
  columnverticalalignment: (block) => <Columns block={ block }
                                               verticalAlignment={ block.verticalAlignment.value }/>,
  image: (block) => <ImageBlock fluid={ block?.file?.childImageSharp?.fluid } url={ block?.url }/>,
  videoblock: (block) => <Video block={ block }/>,
  audioblock: (block) => <Audio block={ block }/>,
  imagegallery: (block) => <ImageGallery block={ block }/>,
  testimonial: (block) => <BlockContainer alignment={ "center" } id={ block.id }>
    <i>"{ block.data.Content }"</i>
  </BlockContainer>,
  markdown: (block) => <Markdown markdown={ block.data.Content }/>,
  testimonialslider: (block) => <TestimonialSlider block={ block }/>,
  contentimagegallery: (block) => <Gallery block={ block }/>,
  contentimageblock: (block) => <GalleryBlock
    title={ block?.title?.value }
    content={ block?.content?.value }
    imageUrl={ block?.image?.media?.publicUrl }
    link={ block?.pageLink?.value }
  />,
  contentslider: (block) => <ContentSlider block={ block }/>,
  relatedlinkgallery: (block) => <RelatedLinkGallery block={ block }/>,
  imagesizeblock: (block) => <ImageSizeBlock block={ block }/>,
  gallery: (block) => <Gallery block={ block }/>
};

let renderers = Object.assign({}, defaultRenderers);

export function register (blockType, renderer) {
  renderers[blockType] = renderer;
}

export function render (blockType, blockData) {
  // eslint-disable-next-line
  let regex = /[^\.]+$/gm;
  let correctBlockType = blockType.match(regex);

  let renderer = renderers[correctBlockType];
  if (renderer) {
    return renderer(blockData);
  }
  console.error("There was no block for " + blockType);
  return <></>;
}

export function resetRegistry () {
  renderers = Object.assign({}, defaultRenderers);
}