import React from "react";
import { GetBranding } from "../GetData";

export const BrandContext = React.createContext();

export const BrandProvider = ({ children, ...props }) => {
  const [tagline, setTagline] = React.useState("");
  let [socialMedia, setSocialMedia] = React.useState([]);

  React.useEffect(() => {

    Promise.resolve(GetBranding())
      .then(response => {
        setTagline(response.tagline);
        setSocialMedia(response.social);
      });
  }, []);

  return (
    <BrandContext.Provider value={ { tagline, socialMedia } }>
      { children }
    </BrandContext.Provider>
  );
};