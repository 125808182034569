/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./Hero.module.scss";
import { Container } from "../Container";
import { ThemeContext } from "../../components/Context/ThemeContext";
import { FluidImage } from "../FluidImage";

const Hero = ({ imageSrc, title, description, children, flexFlow, justifyContent, alignItems, maxHeight, ...props }) => {
  let [theme] = useContext(ThemeContext);

  return <div
    className={ styles.container }
    css={ css`
      color: ${ theme.lightFont };
      max-height: ${ maxHeight };
    ` }
    { ...props }
  >
    <div className={ styles.subContainer }
         css={ css`
        background-color: ${ theme.imageOverlayColorOverview };
      ` }
    >
      <Container
        style={ { display: "flex", flexFlow, justifyContent, alignItems, height: "100%" } }
      >
        { title &&
        <h1 className={ styles.title }>{ title }</h1>
        }
        { description &&
        <p className={ styles.description }>{ description }</p>
        }
        <div>
          { children }
        </div>
      </Container>
    </div>
    { imageSrc &&
    <FluidImage
      style={ { maxHeight } }
      src={ imageSrc }
      alt={title || ""}
      className={ styles.image }/>
    }
  </div>;
};

Hero.propTypes = {
  fluid: PropTypes.any,
  title: PropTypes.node,
  description: PropTypes.node,
  children: PropTypes.node,
  flexFlow: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  maxHeight: PropTypes.string
};

Hero.defaultProps = {
  flexFlow: "column nowrap",
  justifyContent: "flex-end",
  alignItems: "center"
};

export { Hero };