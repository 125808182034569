import React, { useContext } from "react";
import SEO from "../seo";
import { SolidContactButton } from "../Contact/Buttons";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { Hero } from "../../Core/Hero";
import { Container } from "../../Core/Container";
import { Breadcrumb } from "../../Core/Breadcrumb";
import { MasterDetail } from "../../Core/MasterDetail";
import { DarkenOnHoverLink } from "../../Core/DarkenOnHoverLink";
import { BlockContainer } from "../../Core/Blocks/BlockContainer";
import { BlockFactory } from "../../Core/BlockFactory";
import { AllPagesContext } from "../Context/AllPagesContext";
import { LoadingPage } from "../../Core/Loading";

export const Solution = ({ data }) => {
  useResetBlockRegistry();
  let { siteStructure } = useContext(AllPagesContext);

  if (!siteStructure) return <LoadingPage/>;

  const overviewId = data?.parentId;
  let overview = siteStructure?.overview.find((parent) => parent.id === overviewId);
  const pageTitle = data?.title;
  let solutionLinks = siteStructure?.solution?.[overviewId];

  return <>
    <SEO title={ pageTitle }/>
    <Hero title={ pageTitle }
          imageSrc={ data?.regions?.Main?.BackgroundImage?.media?.publicUrl }
          justifyContent={ "flex-end" }
          alignItems={ "center" }
          maxHeight={ "25vh" }
    />
    <Container>
      <Breadcrumb crumbs={ [
        { name: "Home", path: "/" },
        { name: overview.title, path: `${ overview.permalink }` },
        { name: pageTitle, path: `${ data?.permalink }` }
      ] }/>
    </Container>
    <Container style={ { marginTop: "2rem" } }>
      <MasterDetail
        Master={
          <div style={ { display: "flex", flexFlow: "column nowrap" } }>
            <h3 style={ { marginBottom: "5px" } }>
              <DarkenOnHoverLink
                to={ `${ overview?.permalink }` }
              >
                { overview?.navigationTitle }
              </DarkenOnHoverLink>
            </h3>
            {
              solutionLinks?.length > 0 &&
              solutionLinks.map((solution, index) => {
                let solutionLinkTitle = solution?.title;
                return (
                  solution?.blocks?.length > 0 &&
                  <DarkenOnHoverLink
                    to={ `${ solution?.permalink }` }
                    key={ index }
                    currentPage={ pageTitle === solutionLinkTitle }
                  >
                    { solutionLinkTitle }
                  </DarkenOnHoverLink>
                );
              })
            }
            <SolidContactButton hover style={ { margin: "30px 0 10px" } }/>
          </div>
        }>
        <div style={ { margin: "0 0 80px" } }>
          <BlockContainer titleAlignment={ "left" } id={ "overview" }
                          title={ data.regions?.Main?.SummaryText?.value }/>
          <div>{
            data?.blocks?.length > 0 &&
            data?.blocks.map(block => <BlockFactory block={ block } key={ block.id }/>)
          }</div>
        </div>
      </MasterDetail>
    </Container>
  </>;
};