/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { lighten, darken } from "polished";
import { ThemeContext } from "../components/Context/ThemeContext";
import { LinkFactory } from "./LinkFactory";

export const DarkenOnHoverLink = ({ to, children, currentPage = false }) => {
  const [theme] = useContext(ThemeContext);

  return <LinkFactory
    to={ to }
    css={ css`
      color: ${ lighten(.15, theme.darkBackground) } !important;
      padding: 2px 0;
    
      ${ currentPage && `
        color: ${ darken(.5, theme.darkBackground) } !important;
        font-weight: 600;
        width: fit-content;
      `
    }
    
      @media (pointer: fine) {
        &:hover {
          color: ${ darken(.5, theme.darkBackground) } !important;
        }
      }
    ` }
  >
    { children }
  </LinkFactory>;
};