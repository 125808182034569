let axios = require('axios');

const Request  = {
  get: (url, config) => {
    // if (process.env.DEBUG === "true") {
    //   console.log("get url: ", url);
    // }
    // return axios.get(url, { auth: { username: "tsiadmin", password: "tsipassword" }, ...config });
  },
  post: (url, data) => {
      let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
          }
      };
    return axios.post(url, data, { auth: { username: "tsiadmin", password: "tsipassword" }, axiosConfig });
  },
  delete: (url, config) => {
    // if (process.env.DEBUG === "true") {
    //   console.log("delete url: ", url);
    // }
    // return axios.delete(url, { auth: { username: "tsiadmin", password: "tsipassword" }, ...config });
  },
  put: (url, data, config) => {
    // if (process.env.DEBUG === "true") {
    //   console.log("put url: ", url);
    // }
    // return axios.put(url, data, { auth: { username: "tsiadmin", password: "tsipassword" }, ...config });
  }
};

export default Request;
