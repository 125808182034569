/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft";

export const LeftQuote = ({ color, fontSize, ...props }) => {
  return <FontAwesomeIcon
    icon={ faQuoteLeft }
    className={ `${ styles.icon }` }
    css={ css`
      color: ${ color };
      font-size: ${ fontSize };
      ${ { ...props } }
    ` }
  />;
};