import React from "react";
import { RelatedLinkCard } from "./RelatedLinkCard";
import styles from "./RelatedLinkGallery.module.scss";
import { BlockContainer } from "../BlockContainer";

export const RelatedLinkGallery = ({ block }) => (
  <BlockContainer>
    { block?.title?.value && <h3>{ block?.title?.value }</h3> }
    <div className={ styles.cardContainer }>
      { block?.items?.length > 0 &&
      block.items.map((subBlock, index) => {
        return <RelatedLinkCard card={ subBlock } key={ index }/>;
      }) }
    </div>
  </BlockContainer>
);