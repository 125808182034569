/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./MasterDetail.module.scss";
import { ThemeContext } from "../../components/Context/ThemeContext";

const MasterDetail = ({ Master, children, ...props }) => {
  let [theme] = useContext(ThemeContext);

  return <div className={ styles.container } { ...props }>
    <div
      className={ styles.master }
      css={ css`
        &::after {
          background-color: ${ theme.primaryColor };
        }
      ` }
    >
      { Master }
    </div>
    <div className={ styles.detail }>{ children }</div>
  </div>;
};

MasterDetail.propTypes = {
  Master: PropTypes.node,
  children: PropTypes.node
};

export { MasterDetail };