import React, { useContext } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import "./layout.scss";

export const Layout = ({ children, header, footer }) => {
  let [theme] = useContext(ThemeContext);

  return <div
    className="mainContainer"
    style={{
      backgroundColor: `${ theme.lightBackground }`,
      color: `${ theme.darkBackground }`}}
  >
    <Header header={ header }/>
    <main>{ children }</main>
    <Footer footer={ footer }/>
  </div>;
};