import React from "react";
import SEO from "../seo";
import { OpenPositions } from "./OpenPositions/OpenPositions";
import { BlockContainer } from "../../Core/Blocks/BlockContainer";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { Hero } from "../../Core/Hero";
import { Container } from "../../Core/Container";
import { BlockFactory } from "../../Core/BlockFactory";

const NoOpenPositions = ({ message }) => {
  return <>
    <BlockContainer>{ message }</BlockContainer>
  </>;
};

export const Careers = ({ data }) => {
  useResetBlockRegistry();

  const title = data?.title;

  const openPositions = data?.openPositions?.edges
    .reduce((acc, curr) => acc.concat(curr.node), []);

  return <>
    <Hero
      title={ title }
      description={ data?.regions?.Main?.Description?.value }
      imageSrc={ data?.regions?.Main?.BackgroundImage?.media?.publicUrl }
      justifyContent="flex-end"
      alignItems="flex-start"
      maxHeight="40vh"
    />
    <SEO title={ title }/>
    <Container style={ { marginTop: "45px", marginBottom: "100px" } }>
      {
        data?.blocks?.length > 0 &&
        data?.blocks.map(block => <BlockFactory block={ block } key={ block.id }/>)
      }
      { openPositions ?
        <OpenPositions openPositions={ openPositions }/> :
        <NoOpenPositions
          message={ data?.regions?.Main?.NoOpenPositionsMessage?.value }
        />
      }
    </Container>
  </>;
};