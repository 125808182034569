import React, { useEffect, useState } from "react";

export const AllPagesContext = React.createContext();

export const AllPagesProvider = ({ pages, children, ...props }) => {
  let [siteStructure, setSiteStructure] = useState();

  const formatSiteStructure = (allPages) => {
    if (allPages.length > 0) {
      let structure = allPages.reduce((currPage, nextPage) => {
        let pageType = nextPage.typeId.toLowerCase();
        let parentId = nextPage.parentId;

        if (!currPage[pageType]) currPage[pageType] = [];
        if (!parentId) {
          currPage[pageType].push(nextPage);
        }

        if (parentId && !currPage[pageType][parentId]) {
          currPage[pageType][parentId] = [];
        }
        if (parentId) {
          currPage[pageType][parentId].push(nextPage);
        }

        return currPage;
      }, []);

      setSiteStructure(structure);
    }
  };

  useEffect(() => {
    formatSiteStructure(pages);
  }, [pages]);

  return <AllPagesContext.Provider value={ { siteStructure } } { ...props }>
    { children }
  </AllPagesContext.Provider>;
};