import React from "react";
import styles from "./ContentSlider.module.scss";
import Slider from "react-slick";
import { BlockContainer } from "../BlockContainer";
import { ImageSizeBlock } from "../ImageSizeBlock";

export const ContentSlider = ({ block }) => {
  let numberOfSubBlocks = block.items.length;
  let numberOfSlidesToShow = 1;
  if (numberOfSubBlocks >= 2) numberOfSlidesToShow = numberOfSubBlocks - 1;

  let settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 750,
    slidesToShow: Math.min(numberOfSlidesToShow, 3),
    slidesToScroll: 1,
    arrows: false,
    dots: true
  };

  return <BlockContainer>
    <div className={ styles.contentContainer }>
      { block?.title?.value && <h1 className={ styles.title }>{ block.title.value }</h1> }
      { block?.content?.value && <p className={ styles.content }>{ block.content.value }</p> }
    </div>
    <div className={ styles.sliderContainer }>
      <Slider { ...settings }>
        {
          block?.items?.length > 0 &&
          block?.items.map((subBlock, index) => (
            <div key={ index }>
              <ImageSizeBlock block={ subBlock }
                              alt={ `Featured Partner ${ index + 1 }` }
                              style={ { borderRadius: "5px", margin: "auto" } }
              />
            </div>
          ))
        }
      </Slider>
    </div>
  </BlockContainer>;
};