/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

export const Linkedin = ({ color, fontSize, link }) => {

  return (
    <a
      href={ link }
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={ faLinkedinIn }
        className={ styles.icon }
        css={ css`
                color: ${ color };
                font-size: ${ fontSize };
              ` }
      />
    </a>
  );
};