import { formatThemeArray } from "../Layout/formatThemeArray";

const data =  [
    {
      "id": 1,
      "name": "Light Background",
      "color": "#F4F5F6"
    },
    {
      "id": 2,
      "name": "Dark Background",
      "color": "#313539"
    },
    {
      "id": 3,
      "name": "Light Font",
      "color": "#F4F5F6"
    },
    {
      "id": 4,
      "name": "Dark Font",
      "color": "#313539"
    },
    {
      "id": 5,
      "name": "Primary Color",
      "color": "#1791D3"
    },
    {
      "id": 6,
      "name": "Secondary Color",
      "color": "#23498B"
    },
    {
      "id": 7,
      "name": "Light Accent 1",
      "color": "#EAEBED"
    },
    {
      "id": 8,
      "name": "Medium Accent 1",
      "color": "#A1A7AE"
    },
    {
      "id": 9,
      "name": "Dark Accent 1",
      "color": "#313539"
    },
    {
      "id": 10,
      "name": "Image Overlay Color Solution",
      "color": "rgba( 49, 53, 57, .55)"
    },
    {
      "id": 11,
      "name": "Image Overlay Color Overview",
      "color": "rgba( 49, 53, 57, .25)"
    },
    {
      "id": 12,
      "name": "Border Shadow",
      "color": "#A1A7AE"
    }
];

export const GetTheme = () => {
    return formatThemeArray(data);
}