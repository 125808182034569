/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./Separator.module.scss";
import { ThemeContext } from "../../../components/Context/ThemeContext";
import { BlockContainer } from "../BlockContainer";

export const Separator = () => {
  let [theme] = useContext(ThemeContext);

  return <BlockContainer>
    <div
      className={ styles.separator }
      css={ css`
      background-color: ${ theme.primaryColor };
    ` }
    />
  </BlockContainer>;
};