/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useContext } from "react";
import styles from "./GalleryBlock.module.scss";
import { animated, useSpring } from "react-spring";
import { ThemeContext } from "../../../../components/Context/ThemeContext";
import { LinkFactory } from "../../../LinkFactory";
import { FluidImage } from "../../../FluidImage";

export const GalleryBlock = ({ internalLink, link, title, content, imageUrl = "" }) => {
  let correctLink;

  if (link) {
    let parsedLink = JSON.parse(link);
    correctLink = parsedLink.link || parsedLink.permalink;
  }

  if (internalLink) {
    return <LinkFactory to={ internalLink }>
      <Block content={ content }
             title={ title }
             imageUrl={ imageUrl }
      />
    </LinkFactory>;
  }

  if (correctLink) {
    return <LinkFactory to={ correctLink }>
      <Block content={ content }
             title={ title }
             imageUrl={ imageUrl }
      />
    </LinkFactory>;
  }

  return <Block content={ content }
                title={ title }
                imageUrl={ imageUrl }
  />;
};

const Block = ({ title, content, imageUrl = "" }) => {
  let [isSummaryHovered, setSummaryIsHovered] = useState(false);
  let [theme] = useContext(ThemeContext);

  const onMouseEnter = () => setSummaryIsHovered(true);
  const onMouseLeave = () => setSummaryIsHovered(false);

  const spring = useSpring({
    opacity: isSummaryHovered ? 1 : 0,
    transform: isSummaryHovered ? `translateX(0)` : `translateX(45px)`
  });

  return <div
    key={ title }
    className={ `${ styles.solutionContainer } title` }
    css={ css`
        box-shadow: 2px 2px 5px ${ theme?.borderShadow && theme.borderShadow };
        &::after {
          background-color: ${ theme?.imageOverlayColorSolution && theme.imageOverlayColorSolution };
        }
      ` }
    onMouseEnter={ onMouseEnter }
    onMouseLeave={ onMouseLeave }
  >
    {
      imageUrl &&
      <FluidImage
        src={ imageUrl }
        alt='gallery block'
      />
    }
    <div className={ styles.solutionText }
         css={ css`
          color: ${ theme?.lightFont && theme.lightFont };
        ` }
    >
      <h2>{ title }</h2>
      <animated.div
        className={ `${ styles.summaryText }` }
        style={ spring }
      >
        { content }
      </animated.div>
      <p className={ styles.summaryTextMobile }>
        { content }
        </p>
    </div>
  </div>;
};