/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from 'react';
import styles from './LabeledInput.module.scss';
import { FloatingLabel } from '../FloatingLabel';
import { ThemeContext } from "../../../components/Context/ThemeContext";

export const LabeledInput = ({ type, label, className, ...props }) => {
  let [theme] = useContext(ThemeContext);
  let finalClassName = `labeled-input${ className } ${ className } ${ styles.labeledInput }`;

  return (
    <div>
      { !!label && <FloatingLabel>{ label }</FloatingLabel> }
      <input
        className={ finalClassName }
        css={css`
          border: 2px solid ${theme.darkFont};
        `}
        type={ type }
        { ...props }
      />
    </div>
  );
};