import React from "react";
import SEO from "../seo";
import { Hero } from "./Hero";
import { CompanyOverview } from "./CompanyOverview";
import { ContactUsBanner } from "../Contact";
import { useResetBlockRegistry } from "../../Core/util/useResetBlockRegistry";
import { BlockFactory } from "../../Core/BlockFactory";
import { LoadingSegment } from "../../Core/Loading";

export const Home = ({ data }) => {
  useResetBlockRegistry();
  // register("testimonialslider", (block) => <HomepageTestimonialSlider testimonialData={ block }/>);

  if (!data) return <LoadingSegment/>;

  return <>
    <SEO title="Home"/>
    <Hero
      callToAction={ data?.regions?.Main?.CallToAction?.value }
    />
    <CompanyOverview overview={ data?.regions?.Main?.Overview?.value }/>
    {
      data?.blocks?.length > 0 &&
      data.blocks.map((block, index) => <BlockFactory block={ block } key={ index }/>)
    }
    <ContactUsBanner/>
  </>;
};