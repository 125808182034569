/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BlockFactory } from "../../BlockFactory";
import { BlockContainer } from "../BlockContainer";
import styles from "./Columns.module.scss";

export const Columns = ({ block, verticalAlignment }) => {
  const getVerticalAlignment = (value) => {
    if (value === 0) {
      return "flex-start";
    }
    if (value === 1) {
      return "center";
    }
    if (value === 2) {
      return "flex-end";
    }
  };

  return <BlockContainer containerPadding='0 20px'>
    <div className={ `columns is-multiline` }
         css={ css` align-items: ${ getVerticalAlignment(verticalAlignment) }; ` }
    >
      {
        block.items?.length > 0 &&
        block.items.map((sub, index) => (
          <div className={ `column ${ styles.column }` } key={ index }>
            <BlockFactory block={ sub }/>
          </div>
        ))
      }
    </div>
  </BlockContainer>;
};