import React, { useContext, useState, useRef } from "react";
import styles from "../Contact.module.scss";
import { ThemeContext } from "../../Context/ThemeContext";
import Request from "../../../Core/util/Request";
import ReCAPTCHA from "react-google-recaptcha";
import { SmallRedAlertMessage } from "../../../Core/SmallRedAlertMessage";
import { LabeledInput } from "../../../Core/Inputs/LabeledInput";
import { LabeledTextArea } from "../../../Core/Inputs/LabeledTextArea";
import "./Form.css";

export const Form = () => {
  let blankForm = {
    email: "",
    name: "",
    message: "",
    phoneNumber: ""
  };
  let [theme] = useContext(ThemeContext);
  let [form, setForm] = useState(blankForm);
  let [errors, setErrors] = useState({});
  let [inputConfirmation, setInputConfirmation] = useState(<p></p>);
  const [submitActive, setSubmitActive] = useState(true);
  const recaptchaRef = useRef(null);

  const apiServer = "https://www.tsidata.com";
  //const apiServer = 'https://localhost:44344';

  const onInputChange = (key) => (event) => {
    setForm({ ...form, [key]: event.target.value });
  };

  const validateInputs = () => {
    setInputConfirmation(<p></p>);
    const isInvalidInput = /^\s*$/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = {};
    Object.keys(form)
      .forEach(input => {
        if (input !== 'phoneNumber' && isInvalidInput.test(form[input])) {
          error[input] = "Required";
          setSubmitActive(true);
        }
        else if (input === 'email' && !re.test(String(form[input]).toLowerCase())) {
          error[input] = "Invalid email format";
          setSubmitActive(true);
        }
      });
    setErrors({ ...error });
    if (Object.entries(error).length === 0) {
      getRecaptchaValue();
    }
  };

  const getRecaptchaValue = () => {
    if (process.env.NODE_ENV === "development") {
      console.info("recaptcha faked due to development environment");
      submitForm(true);
    }
    recaptchaRef.current.execute();
  };

  const clearInputs = () => {
    setForm(blankForm);
  };

  const submitForm = (value) => {
      if (value) {
      Request.post(apiServer + "/api/Contact", { ...form, recaptcha: value })
        .then(response => {
          if (response.status === 200) {
            clearInputs();
            setInputConfirmation(<p style={{ color: "green" }}>Message sent successfully</p>);
            setSubmitActive(true);
          }
        })
        .then(() => {
          recaptchaRef.current.reset();
        })
        .catch(error => {
            setInputConfirmation(<p style={{ color: "red" }}>There was a problem sending your contact information, please try again</p>);
            setSubmitActive(true);
            console.error(error);
        });
    }
  };

  return <form className={ styles.form }>
    <h3>Contact Us</h3>
    <LabeledInput
      type="email"
      label="Email *"
      value={ form.email }
      maxLength={500}
      onChange={ onInputChange("email") }
    />
    <SmallRedAlertMessage className={ styles.alert }>{ errors.email }</SmallRedAlertMessage>
    <LabeledInput
      type="text"
      label="Name *"
      value={ form.name }
      maxLength={500}
      onChange={ onInputChange("name") }
    />
    <SmallRedAlertMessage className={ styles.alert }>{ errors.name }</SmallRedAlertMessage>
    <LabeledInput
        label="Phone Number"
        type="number"
        value={form.phoneNumber}
        maxLength={25}
        onChange={onInputChange("phoneNumber")}
    />
    <LabeledTextArea
      label="Message *"
      value={ form.message }
      onChange={ onInputChange("message") }
    />
    <SmallRedAlertMessage className={ styles.alert }>{ errors.message }</SmallRedAlertMessage>
    <div>* indicates a required field</div>
    <ReCAPTCHA
      ref={ recaptchaRef }
      sitekey={ '6LfoIcwUAAAAAPJ7_7rE2axU2FSnEy9ilWNNk6x3' }
      size="invisible"
      onChange={ submitForm }
    />
    <button
      className={ styles.submit }
      style={{
        color: theme.lightFont,
        border: "none",
        display: "relative",
        zIndex: "999",
        backgroundColor: submitActive? theme.primaryColor : theme.lightAccent1,
        cursor: "pointer",
      }}
      type="button"
      disabled={!submitActive}
      onClick={() => {setSubmitActive(false); validateInputs();} }
    >
          Submit
    </button>
      <div> { inputConfirmation } </div>
  </form>;
};