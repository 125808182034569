/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

export const Twitter = ({ color, fontSize, link }) => {
  return (
    <a
      href={ link }
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={ faTwitter }
        className={ styles.icon }
        css={ css`
            color: ${ color };
            font-size: ${ fontSize };
          ` }
      />
    </a>
  );
};