/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styles from "./Icon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMediumM } from "@fortawesome/free-brands-svg-icons/faMediumM";

export const Medium = ({ color, fontSize, link }) => {

  return (
    <a
      href={ link }
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={ faMediumM }
        className={ styles.icon }
        css={ css`
              color: ${ color };
              font-size: ${ fontSize };
            ` }
      />
    </a>
  );
};