import React from 'react';
import style from './FluidImage.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const FluidImage = ({ src, alt, className, ...props }) => {

  return <LazyLoadImage
    alt={ alt }
    className={ `${ className } ${ style.image }` }
    { ...props }
    src={ src }
  />
}