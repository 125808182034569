/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import styles from "./RelatedLinkCard.module.scss";
import { ThemeContext } from "../../../../components/Context/ThemeContext";
import { FluidImage } from "../../../FluidImage";
import { LinkFactory } from "../../../LinkFactory";

export const RelatedLinkCard = ({ card }) => {
  let [theme] = useContext(ThemeContext);

  return <div className={ styles.container }
              css={ css`
                background-color: ${ theme.lightAccent1 };
              ` }
  >
    <LinkFactory to={ `${ card?.pageLink?.page?.permalink }` }
                 style={ { width: "100%", height: "100%", position: "absolute" } }>
      <div css={ css`
                color: ${ theme.lightAccent1 };
              ` }
           className={ styles.content }
      >
        <p className={ styles.text }>
          { card.title?.value }
          <br/>
          { card?.pageLink?.page?.title }
        </p>
        <div className={ styles.coloredDiv }
             css={ css`
              background-color: ${ theme.primaryColor };
             ` }
        />
      </div>
      {
        card?.image?.media?.publicUrl &&
        <FluidImage src={ card.image.media.publicUrl } alt={ "Related link" }/>
      }
    </LinkFactory>
  </div>;
};