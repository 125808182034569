export const GetAboutPages = () => {
    return (
        [
            {
              "regions": {
                "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                "Main": {
                  "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                  "Description": {
                    "$type": "Piranha.Extend.Fields.StringField, Piranha",
                    "value": null
                  },
                  "BackgroundImage": {
                    "$type": "Piranha.Extend.Fields.ImageField, Piranha",
                    "id": "3924e6b9-fbd1-4b89-9cf3-7aa4d2d98ba0",
                    "media": {
                      "versions": [
                        {
                          "id": "a350bada-52d2-4dc3-bcc0-efe02922770e",
                          "size": 54617,
                          "width": 210,
                          "height": 160,
                          "fileExtension": ".jpg"
                        }
                      ],
                      "id": "3924e6b9-fbd1-4b89-9cf3-7aa4d2d98ba0",
                      "folderId": null,
                      "type": 2,
                      "filename": "About - Technical Partners.jpg",
                      "contentType": "image/jpeg",
                      "size": 5053335,
                      "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/3924e6b9-fbd1-4b89-9cf3-7aa4d2d98ba0-About - Technical Partners.jpg",
                      "width": 5150,
                      "height": 1933,
                      "created": "2020-01-30T17:04:46.0740993",
                      "lastModified": "2020-01-30T17:04:47.0625886"
                    },
                    "hasValue": true
                  }
                }
              },
              "isStartPage": false,
              "siteId": "80db1de0-d8c3-4e8f-a1b1-0853d10215a3",
              "parentId": null,
              "sortOrder": 2,
              "navigationTitle": null,
              "isHidden": false,
              "redirectUrl": null,
              "redirectType": 0,
              "originalPageId": null,
              "blocks": [
                {
                  "$type": "Piranha.Extend.Blocks.TextBlock, Piranha",
                  "body": {
                    "value": "<span style=\"color: rgb(90, 93, 96); font-family: Lato, sans-serif;\">TSI is a provider of data services with a proven track record and experience in delivering customized solutions into many business environments. That's why we partner with companies to provide the best technology to fit your needs.&nbsp; </span><span style=\"color: rgb(90, 93, 96); font-family: Lato, sans-serif;\"><span style=\"color: rgb(90, 93, 96); font-family: Lato, sans-serif;\">We\n will evaluate your business needs, provide specifications for the \nhardware, software and architecture, and provide equipment that meets \nyour requirement and budget. TSI is a reseller for virtually every major\n brand of business hardware and software.<br>Call TSI today to see how we can save you money through our partnerships.<br></span></span>"
                  },
                  "id": "25c3f673-ca6f-48b9-ab63-9b84e30020a5",
                  "type": "Piranha.Extend.Blocks.TextBlock"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ContentImageGallery, Api",
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "3ad178f9-65e8-49dd-ade4-544ed3e8f22a",
                        "media": {
                          "versions": [
                            {
                              "id": "1153ac35-8300-45cc-b2ab-ab9f5b5374aa",
                              "size": 6034,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "3ad178f9-65e8-49dd-ade4-544ed3e8f22a",
                          "folderId": null,
                          "type": 2,
                          "filename": "AWS_PARTNER.png",
                          "contentType": "image/png",
                          "size": 3184,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/3ad178f9-65e8-49dd-ade4-544ed3e8f22a-AWS_PARTNER.png",
                          "width": 310,
                          "height": 163,
                          "created": "2019-12-20T19:36:56.9225436",
                          "lastModified": "2019-12-20T19:36:56.9227058"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "Amazon Web Services"
                      },
                      "content": {
                        "value": "AWS Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"AWS\",\"link\":\"https://aws.amazon.com/\"}"
                      },
                      "id": "74271d48-94a2-4e96-8655-f8b70e4c45ab",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "1f27c140-a5fc-4954-8608-69abf7a38436",
                        "media": {
                          "versions": [
                            {
                              "id": "187f5b0a-6581-40e2-8777-173590734977",
                              "size": 24853,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".jpg"
                            }
                          ],
                          "id": "1f27c140-a5fc-4954-8608-69abf7a38436",
                          "folderId": null,
                          "type": 2,
                          "filename": "cisco-logo.jpg",
                          "contentType": "image/jpeg",
                          "size": 97762,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/1f27c140-a5fc-4954-8608-69abf7a38436-cisco-logo.jpg",
                          "width": 1000,
                          "height": 563,
                          "created": "2019-12-06T11:31:53.8044956",
                          "lastModified": "2019-12-06T11:31:53.8045632"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "Cisco"
                      },
                      "content": {
                        "value": "Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"Cisco\",\"link\":\"https://www.cisco.com/\"}"
                      },
                      "id": "a56f2fb0-0550-4ce9-b25f-b5d8dbdb5c9b",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "5f398f4c-e1a6-43c7-9bc8-56c77d43c169",
                        "media": {
                          "versions": [
                            {
                              "id": "618bca9c-d239-4529-82e7-d74c2a066d30",
                              "size": 19616,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "5f398f4c-e1a6-43c7-9bc8-56c77d43c169",
                          "folderId": null,
                          "type": 2,
                          "filename": "EMC_BPP_PartnerLogo_Square.png",
                          "contentType": "image/png",
                          "size": 4114,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/5f398f4c-e1a6-43c7-9bc8-56c77d43c169-EMC_BPP_PartnerLogo_Square.png",
                          "width": 137,
                          "height": 138,
                          "created": "2019-12-20T19:40:47.4111597",
                          "lastModified": "2019-12-20T19:40:47.5746831"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "DellEMC"
                      },
                      "content": {
                        "value": "Premiere Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"DellEMC\",\"link\":\"https://www.delltechnologies.com/en-us/products/index.htm#\"}"
                      },
                      "id": "93d82b35-17e2-421c-8d92-a4f4ec152883",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "bca6d9c2-c1f4-4d4e-9888-0179b1f8dbf2",
                        "media": {
                          "versions": [
                            {
                              "id": "74a06b4c-8920-4907-b6d4-228a1d215015",
                              "size": 37312,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".jpg"
                            }
                          ],
                          "id": "bca6d9c2-c1f4-4d4e-9888-0179b1f8dbf2",
                          "folderId": null,
                          "type": 2,
                          "filename": "global-technology.jpg",
                          "contentType": "image/jpeg",
                          "size": 380059,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/bca6d9c2-c1f4-4d4e-9888-0179b1f8dbf2-global-technology.jpg",
                          "width": 1800,
                          "height": 895,
                          "created": "2019-11-21T10:26:37.6643632",
                          "lastModified": "2019-11-21T10:26:37.7760014"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "GTA"
                      },
                      "content": {
                        "value": "Silver Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"GTA\",\"link\":\"https://gtatelecom.com/\"}"
                      },
                      "id": "ce776383-545b-453d-b1be-f275cb814e3a",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "1779848c-f6e2-4c43-8fbf-31e2dda6c2bf",
                        "media": {
                          "versions": [
                            {
                              "id": "9baf587f-8337-4e5e-9e95-bbb38a4bb0c1",
                              "size": 5299,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "1779848c-f6e2-4c43-8fbf-31e2dda6c2bf",
                          "folderId": null,
                          "type": 2,
                          "filename": "microsoft-logo.png",
                          "contentType": "image/png",
                          "size": 22130,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/1779848c-f6e2-4c43-8fbf-31e2dda6c2bf-microsoft-logo.png",
                          "width": 2008,
                          "height": 900,
                          "created": "2019-11-21T10:27:37.1867433",
                          "lastModified": "2019-12-06T11:32:04.4217959"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "Microsoft"
                      },
                      "content": {
                        "value": "Certified Gold Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"Microsoft\",\"link\":\"https://azure.microsoft.com/en-us/\"}"
                      },
                      "id": "24f5cf0e-767c-4335-9131-75ade1351e42",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "23a2ecf9-ac34-4509-9fa1-56ca5aff577b",
                        "media": {
                          "versions": [
                            {
                              "id": "c6142cc2-5702-4fd0-8cc1-319960c5b23d",
                              "size": 8938,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "23a2ecf9-ac34-4509-9fa1-56ca5aff577b",
                          "folderId": null,
                          "type": 2,
                          "filename": "partner logos_Premier Partner.png",
                          "contentType": "image/png",
                          "size": 24443,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/23a2ecf9-ac34-4509-9fa1-56ca5aff577b-partner logos_Premier Partner.png",
                          "width": 1276,
                          "height": 421,
                          "created": "2021-01-07T20:55:19.8817241",
                          "lastModified": "2021-01-07T20:55:20.2689484"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "OSIsoft"
                      },
                      "content": {
                        "value": "Certified Premier Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"OSISoft\",\"link\":\"https://resources.osisoft.com/marketplace/partner-detail/?id=a2I1I000000ocAmUAI\"}"
                      },
                      "id": "d598c86b-5421-4e56-939c-028b269f841a",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "12464f3b-07dc-4f85-b018-a76bf8518dcc",
                        "media": {
                          "versions": [
                            {
                              "id": "52459468-2d79-4cc1-ad57-1fb6d3b62d3b",
                              "size": 30770,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "12464f3b-07dc-4f85-b018-a76bf8518dcc",
                          "folderId": null,
                          "type": 2,
                          "filename": "Pax8_Logo_500px.png",
                          "contentType": "image/png",
                          "size": 93426,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/12464f3b-07dc-4f85-b018-a76bf8518dcc-Pax8_Logo_500px.png",
                          "width": 500,
                          "height": 329,
                          "created": "2019-12-20T19:39:14.7758397",
                          "lastModified": "2019-12-20T20:07:08.8415969"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "Pax8"
                      },
                      "content": {
                        "value": "Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"Pax8\",\"link\":\"https://www.pax8.com/home\"}"
                      },
                      "id": "d693e388-6bdc-4b2e-a4a2-87abde3cf4b8",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    },
                    {
                      "$type": "Api.CMS.BlockTypes.ContentImageBlock, Api",
                      "image": {
                        "id": "b6d11ad0-1bb2-4b67-bc52-873dd3187ff1",
                        "media": {
                          "versions": [
                            {
                              "id": "226f70e9-4098-4f89-a29a-db86df22c67f",
                              "size": 12290,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "b6d11ad0-1bb2-4b67-bc52-873dd3187ff1",
                          "folderId": null,
                          "type": 2,
                          "filename": "vmware-logo.png",
                          "contentType": "image/png",
                          "size": 9948,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/b6d11ad0-1bb2-4b67-bc52-873dd3187ff1-vmware-logo.png",
                          "width": 195,
                          "height": 195,
                          "created": "2019-12-06T11:31:53.7125048",
                          "lastModified": "2019-12-06T11:31:53.7134572"
                        },
                        "hasValue": true
                      },
                      "title": {
                        "value": "VMWare"
                      },
                      "content": {
                        "value": "Certified Solution Provider Partner"
                      },
                      "pageLink": {
                        "value": "{\"title\":\"VMWare\",\"link\":\"https://www.vmware.com\"}"
                      },
                      "id": "1e32517b-f31e-42c2-a039-7ddd32582e4d",
                      "type": "Api.CMS.BlockTypes.ContentImageBlock"
                    }
                  ],
                  "id": "cf3dd4b9-8070-4b31-b2a0-af33a80d3bd7",
                  "type": "Api.CMS.BlockTypes.ContentImageGallery"
                }
              ],
              "slug": "technical-partners",
              "permalink": "/technical-partners",
              "metaKeywords": null,
              "metaDescription": null,
              "route": "/api/cms/about",
              "published": "2019-11-05T08:31:00",
              "id": "c80a2bf7-5195-437d-a765-ca260b85c758",
              "typeId": "About",
              "title": "Technical Partners",
              "created": "2021-01-08T12:34:24.0289316",
              "lastModified": "2021-01-08T12:34:24.0289176"
            },
            {
              "regions": {
                "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                "Main": {
                  "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                  "Description": {
                    "$type": "Piranha.Extend.Fields.StringField, Piranha",
                    "value": "TSI is an end-to-end solution with the ability to successfully bridge the gap between Infomation Technology, Operational Technology, and Software services.."
                  },
                  "BackgroundImage": {
                    "$type": "Piranha.Extend.Fields.ImageField, Piranha",
                    "id": null,
                    "media": null,
                    "hasValue": false
                  }
                }
              },
              "isStartPage": false,
              "siteId": "80db1de0-d8c3-4e8f-a1b1-0853d10215a3",
              "parentId": null,
              "sortOrder": 3,
              "navigationTitle": null,
              "isHidden": false,
              "redirectUrl": null,
              "redirectType": 0,
              "originalPageId": null,
              "blocks": [
                {
                  "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                  "body": {
                    "value": "<p>TSI is a Veteran-Owned Small Business (VOSB) located in the bustling DFW metroplex.&nbsp; It was founded in 2002 and currently fields a dynamic team of engineers, builders, and problem solvers who bridge the Information Technology/Operational Technology divide. &nbsp;Our skills and experience, combined with our use of cutting-edge technology, allows us to consistently deliver unique solutions to our customers. &nbsp;We help deliver actionable information at the right time, from the Board Room to the Control Room, while maintaining the security and integrity of operations.</p><p>TSI has created a strong culture of excellence built around effective communication, exceptional individual and team performance, and industry leading execution.&nbsp; Our staff is dedicated to consistently exceeding client expectations and delivering superior results.&nbsp;</p><p>TSI team members take personal ownership of project tasks and design creative and efficient solutions to client problems.&nbsp; Our project teams work closely with clients, vendors, and other support staff to implement robust systems that adhere to strict quality and security constraints.</p><p>TSI delivers on time, on budget, and fully functional results.&nbsp; We continue to earn your trust on every project, one task at a time.</p>"
                  },
                  "id": "a57c0034-33da-4ef4-a004-4e35a29e5242",
                  "type": "Piranha.Extend.Blocks.HtmlBlock"
                }
              ],
              "slug": "company-overview",
              "permalink": "/company-overview",
              "metaKeywords": null,
              "metaDescription": null,
              "route": "/api/cms/about",
              "published": "2019-11-05T08:33:00",
              "id": "e4dcda5d-914b-428c-a68a-851e5f47b53a",
              "typeId": "About",
              "title": "Company Overview",
              "created": "2020-10-28T15:11:38.5024488",
              "lastModified": "2020-10-28T15:11:38.5024417"
            },
            {
              "regions": {
                "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                "Main": {
                  "$type": "System.Dynamic.ExpandoObject, System.Linq.Expressions",
                  "Description": {
                    "$type": "Piranha.Extend.Fields.StringField, Piranha",
                    "value": ""
                  },
                  "BackgroundImage": {
                    "$type": "Piranha.Extend.Fields.ImageField, Piranha",
                    "id": null,
                    "media": null,
                    "hasValue": false
                  }
                }
              },
              "isStartPage": false,
              "siteId": "80db1de0-d8c3-4e8f-a1b1-0853d10215a3",
              "parentId": null,
              "sortOrder": 4,
              "navigationTitle": null,
              "isHidden": false,
              "redirectUrl": null,
              "redirectType": 0,
              "originalPageId": null,
              "blocks": [
                {
                  "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                  "body": {
                    "value": "<p>The TSI culture is one of respect, teamwork, and dedication to success.&nbsp; The following core value statements are not just inspirational messages posted on the office walls.&nbsp; TSI core value statements are critical expectations for all TSI employees that drive daily processes and projects.</p><p>What does this mean for you?&nbsp; You can count on TSI to deliver the results that you expect while knowing that our staff is operating in an ethical and efficient manner.&nbsp; We do things well and we do them right.</p><p>The following core value statements embody the TSI culture:</p>"
                  },
                  "id": "97f385c8-85c2-44d7-bea4-d58910e1f21d",
                  "type": "Piranha.Extend.Blocks.HtmlBlock"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ColumnVerticalAlignment, Api",
                  "verticalAlignment": {
                    "value": 1
                  },
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ImageSizeBlock, Api",
                      "image": {
                        "id": "0afc4155-195c-4eb4-b63b-0f36e57e745f",
                        "media": {
                          "versions": [
                            {
                              "id": "044a7ebd-b660-46d8-8b81-16921e0924bb",
                              "size": 8831,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "0afc4155-195c-4eb4-b63b-0f36e57e745f",
                          "folderId": null,
                          "type": 2,
                          "filename": "Blue_ExCoreValue.png",
                          "contentType": "image/png",
                          "size": 22844,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/0afc4155-195c-4eb4-b63b-0f36e57e745f-Blue_ExCoreValue.png",
                          "width": 514,
                          "height": 514,
                          "created": "2020-01-30T18:00:57.7904881",
                          "lastModified": "2020-01-30T18:30:51.379313"
                        },
                        "hasValue": true
                      },
                      "selectedWidthOption": {
                        "name": "Custom",
                        "width": "200"
                      },
                      "id": "62742fdd-e39c-4b4e-913e-ab84cb4b3b53",
                      "type": "Api.CMS.BlockTypes.ImageSizeBlock"
                    },
                    {
                      "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                      "body": {
                        "value": "<p>We embody <strong>excellence</strong> in our attitude, performance, and results.</p>"
                      },
                      "id": "dadbd774-e28a-4551-ab6c-73aaeab1d563",
                      "type": "Piranha.Extend.Blocks.HtmlBlock"
                    }
                  ],
                  "id": "6a21f69b-388b-4b0f-baa4-a08f868ec14b",
                  "type": "Api.CMS.BlockTypes.ColumnVerticalAlignment"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ColumnVerticalAlignment, Api",
                  "verticalAlignment": {
                    "value": 1
                  },
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ImageSizeBlock, Api",
                      "image": {
                        "id": "04c8bbec-552a-4965-976f-7351a6f5f382",
                        "media": {
                          "versions": [
                            {
                              "id": "ca3d5a63-4d2e-42fb-bc73-efecc35226c2",
                              "size": 8964,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "04c8bbec-552a-4965-976f-7351a6f5f382",
                          "folderId": null,
                          "type": 2,
                          "filename": "Blue_CCoreValue.png",
                          "contentType": "image/png",
                          "size": 23573,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/04c8bbec-552a-4965-976f-7351a6f5f382-Blue_CCoreValue.png",
                          "width": 514,
                          "height": 514,
                          "created": "2020-01-30T18:00:57.6190383",
                          "lastModified": "2020-01-30T18:00:59.1113932"
                        },
                        "hasValue": true
                      },
                      "selectedWidthOption": {
                        "name": "Custom",
                        "width": "200"
                      },
                      "id": "f3039acb-44a3-4015-aaa1-1c35b19166f9",
                      "type": "Api.CMS.BlockTypes.ImageSizeBlock"
                    },
                    {
                      "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                      "body": {
                        "value": "<p>We <strong>communicate</strong> clearly and effectively.</p>"
                      },
                      "id": "cb097aaa-32e1-46be-bd5d-af70442fabd7",
                      "type": "Piranha.Extend.Blocks.HtmlBlock"
                    }
                  ],
                  "id": "79ca9a7d-68a2-452c-a3dd-a0e759d930c1",
                  "type": "Api.CMS.BlockTypes.ColumnVerticalAlignment"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ColumnVerticalAlignment, Api",
                  "verticalAlignment": {
                    "value": 1
                  },
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ImageSizeBlock, Api",
                      "image": {
                        "id": "81bd4c5b-1abe-44f4-9c5b-42636cc2cc3f",
                        "media": {
                          "versions": [
                            {
                              "id": "f29d92c5-2a67-48a3-bbe7-8176f230f7e7",
                              "size": 7039,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "81bd4c5b-1abe-44f4-9c5b-42636cc2cc3f",
                          "folderId": null,
                          "type": 2,
                          "filename": "Blue_ICoreVaulue.png",
                          "contentType": "image/png",
                          "size": 20204,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/81bd4c5b-1abe-44f4-9c5b-42636cc2cc3f-Blue_ICoreVaulue.png",
                          "width": 514,
                          "height": 514,
                          "created": "2020-01-30T18:00:57.8307477",
                          "lastModified": "2020-01-30T18:30:51.4563152"
                        },
                        "hasValue": true
                      },
                      "selectedWidthOption": {
                        "name": "Custom",
                        "width": "200"
                      },
                      "id": "4a603b2b-8937-4e7d-9f70-c59dfdb0416c",
                      "type": "Api.CMS.BlockTypes.ImageSizeBlock"
                    },
                    {
                      "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                      "body": {
                        "value": "<p>We perform <strong>individually</strong> with integrity, passion, and accountability.</p>"
                      },
                      "id": "53774cbb-191a-4788-9254-3c9522e88f37",
                      "type": "Piranha.Extend.Blocks.HtmlBlock"
                    }
                  ],
                  "id": "92d7b778-06e1-4b69-972f-ff5e8199fca8",
                  "type": "Api.CMS.BlockTypes.ColumnVerticalAlignment"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ColumnVerticalAlignment, Api",
                  "verticalAlignment": {
                    "value": 1
                  },
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ImageSizeBlock, Api",
                      "image": {
                        "id": "bec8fa8a-1c69-452e-81b8-436f1b2b0e02",
                        "media": {
                          "versions": [
                            {
                              "id": "62c69804-aed7-47d9-9e14-6021bd71de45",
                              "size": 7216,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "bec8fa8a-1c69-452e-81b8-436f1b2b0e02",
                          "folderId": null,
                          "type": 2,
                          "filename": "Blue_TCoreValue.png",
                          "contentType": "image/png",
                          "size": 20279,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/bec8fa8a-1c69-452e-81b8-436f1b2b0e02-Blue_TCoreValue.png",
                          "width": 514,
                          "height": 514,
                          "created": "2020-01-30T18:00:57.9903877",
                          "lastModified": "2020-01-30T18:00:59.1671234"
                        },
                        "hasValue": true
                      },
                      "selectedWidthOption": {
                        "name": "Custom",
                        "width": "200"
                      },
                      "id": "bf2cef2a-345b-49b2-8c59-797e4b5d64d2",
                      "type": "Api.CMS.BlockTypes.ImageSizeBlock"
                    },
                    {
                      "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                      "body": {
                        "value": "<p>We perform together with humility, respect, and <strong>teamwork</strong>.</p>"
                      },
                      "id": "d6c93ba4-1a23-4aac-9c01-649f939d1000",
                      "type": "Piranha.Extend.Blocks.HtmlBlock"
                    }
                  ],
                  "id": "8fe7a20b-f51e-4c30-acbf-a9fa2e578c1d",
                  "type": "Api.CMS.BlockTypes.ColumnVerticalAlignment"
                },
                {
                  "$type": "Api.CMS.BlockTypes.ColumnVerticalAlignment, Api",
                  "verticalAlignment": {
                    "value": 1
                  },
                  "items": [
                    {
                      "$type": "Api.CMS.BlockTypes.ImageSizeBlock, Api",
                      "image": {
                        "id": "00cdef40-3d7d-4cd7-b9b7-d225deee2d51",
                        "media": {
                          "versions": [
                            {
                              "id": "cf34cbb5-4d14-42a5-b501-f935edf1f936",
                              "size": 7350,
                              "width": 210,
                              "height": 160,
                              "fileExtension": ".png"
                            }
                          ],
                          "id": "00cdef40-3d7d-4cd7-b9b7-d225deee2d51",
                          "folderId": null,
                          "type": 2,
                          "filename": "Blue_ECoreValue.png",
                          "contentType": "image/png",
                          "size": 20378,
                          "publicUrl": "https://tsiwsprodstorage.blob.core.windows.net/uploads/00cdef40-3d7d-4cd7-b9b7-d225deee2d51-Blue_ECoreValue.png",
                          "width": 514,
                          "height": 514,
                          "created": "2020-01-30T18:00:57.7019868",
                          "lastModified": "2020-01-30T18:30:51.4806913"
                        },
                        "hasValue": true
                      },
                      "selectedWidthOption": {
                        "name": "Custom",
                        "width": "200"
                      },
                      "id": "068b56bb-d67a-4df4-8402-af3da4125e2f",
                      "type": "Api.CMS.BlockTypes.ImageSizeBlock"
                    },
                    {
                      "$type": "Piranha.Extend.Blocks.HtmlBlock, Piranha",
                      "body": {
                        "value": "<p>We <strong>execute</strong> efficiently through timeliness, accuracy, and completeness.</p>"
                      },
                      "id": "45932e47-3f80-49bb-9ed0-cb017a3132ac",
                      "type": "Piranha.Extend.Blocks.HtmlBlock"
                    }
                  ],
                  "id": "b59579e9-8ed3-473b-bbf7-38f085a5750f",
                  "type": "Api.CMS.BlockTypes.ColumnVerticalAlignment"
                }
              ],
              "slug": "culture-and-core-values",
              "permalink": "/culture-and-core-values",
              "metaKeywords": null,
              "metaDescription": null,
              "route": "/api/cms/about",
              "published": "2019-11-05T08:43:00",
              "id": "1fbf0402-049b-4c44-8193-6dfe3e997b6c",
              "typeId": "About",
              "title": "Culture and Core Values",
              "created": "2021-10-25T08:08:45.6761659",
              "lastModified": "2021-10-25T08:08:45.6761527"
            }
        ]          
    )
}